import React from 'react'
import HeaderBannerPages from '../../Components/Sections/HeaderBannerPages/HeaderBannerPages'

function Login() {
  return (
   <>
   <HeaderBannerPages text="Login" />
   </>
  )
}

export default Login