import React from 'react'

function PathCheck() {
  return (
    <>
        <svg  className='svgCheckMain'  width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className='svgCheck' d="M1.42773 6.10941L5.56614 10.2478L14.6965 1.11743" stroke="#6737B4" stroke-width="2.5"/>
</svg>


    </>
  )
}

export default PathCheck